import React from "react"
import * as styles from "./Widget.module.scss"

const Widget = ({ title, children }) => (
  <>
    <aside className={styles.widget}>
      {title && (
        <div className={styles.title}>
          <span className={styles.titleText}>{title}</span>
        </div>
      )}
      {children}
    </aside>
  </>
)

export default Widget
