import React from "react"
import { AboutMe, Other, SocialProfiles } from "../widgets"

const Header = () => (
  <>
    <AboutMe />
    <SocialProfiles />
    <Other />
  </>
)

export default Header
