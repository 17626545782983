import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import get from 'lodash.get';
import { useLang } from '../../context';
import { translate } from '../../translations';
import * as styles from './Header.module.scss';

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "dslogo_small.png" }) {
        childImageSharp {
          fixed(width: 455, height: 53) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const lang = useLang();

  const file = get(data, 'file');

  return (
    <header>
      <Link to={translate(lang, 'menu.homePage.url')}>
        <Img
          className={styles.logo}
          fixed={file.childImageSharp.fixed}
          alt="Damian Sowa logo"
        />
      </Link>
    </header>
  )
}

export default Header
