import React from "react"
import { useLang } from "../../../context"
import { translate } from "../../../translations"
import Widget from "../../Widget"

const SocialProfiles = () => {
  const lang = useLang()

  return (
    <Widget title={translate(lang, "sideBar.stayInTouch")}>
      <div>
        {translate(lang, "sideBar.stayInTouch.description")}{" "}
        <b>
          <a href="/out/sowa.pm" target="_blank" rel="nofollow noreferrer">
            https://sowa.pm
          </a>
        </b>
      </div>

      <br />
      <div>{translate(lang, "sideBar.stayInTouch.myLinks")}</div>
      <ul className="list-disc list-inside">
        <li>
          <a href="https://www.mffn.pl" target="_blank" rel="nofollow noreferrer">
            <span className="text-gold underline">mffn.pl</span> - my projects
          </a>
        </li>
        <li>
          <a href="https://expat-wallet.com" target="_blank" rel="nofollow noreferrer">
            <span className="text-gold underline">expat-wallet.com</span> - blog
            in English about expat finances
          </a>
        </li>
        <li>
          <a href="https://www.sowa.pm" target="_blank" rel="nofollow noreferrer">
            <span className="text-gold underline">sowa.pm</span> - links about
            me and contact
          </a>
        </li>
        <li>
          <a href="https://www.drivexam.com" target="_blank" rel="nofollow noreferrer">
            <span className="text-gold underline">drivexam.com</span> - learn
            for written driving exam in Asia
          </a>
        </li>
        <li>
          <a href="https://www.korea.drivexam.com" target="_blank" rel="nofollow noreferrer">
            <span className="text-gold underline">korea.drivexam.com</span> -
            learn for written driving exam in Korea
          </a>
        </li>
        <li>
          <a href="https://www.japan.drivexam.com" target="_blank" rel="nofollow noreferrer">
            <span className="text-gold underline">japan.drivexam.com</span> -
            learn for written driving exam in Japan
          </a>
        </li>
        <li>
          <a href="https://www.driving-exam-thailand.com" target="_blank" rel="nofollow noreferrer">
            <span className="text-gold underline">
              driving-exam-thailand.com
            </span>{" "}
            - learn for written driving exam in Thailand
          </a>
        </li>

        <li>
          <a href="https://chromewebstore.google.com/detail/booking-helper-google-rev/jdedooomfkfpaphmachcibnagigciagp" target="_blank" rel="nofollow noreferrer">
            <span className="text-gold underline">
            Booking helper
            </span>{" "}
            -  Google reviews for hotels (chrome extension for booking.com)
          </a>
        </li>

      </ul>
    </Widget>
  )
}

export default SocialProfiles
