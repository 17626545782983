import React from 'react';
import { Link } from 'gatsby';
import { useLang } from '../../context';
import { BurgerMenu } from '../../svgs';
import { translate, getUrlPrefix } from '../../translations';

const Menu = () => {
  const lang = useLang();

  return (
    <>
      <div className="flex-col space-y-2 w-full px-10 box-border mb-10 hidden sm:flex">
        <div className="navbar mb-2 shadow-lg bg-neutral text-neutral-content rounded-box">
          <div className="flex-none px-2 mx-2 ">
            <div className="flex items-stretch">
              <Link to={translate(lang, 'menu.homePage.url')} className="btn btn-ghost btn-sm rounded-btn">
                {translate(lang, 'menu.homePage')}
              </Link>
  
              {/* {translate(lang, 'menu.dutchAccountsRanking.url') && (
                <Link to={translate(lang, 'menu.dutchAccountsRanking.url')} className="btn btn-ghost btn-sm rounded-btn">
                  {translate(lang, 'menu.dutchAccountsRanking')}
                </Link>
              )} */}

              {translate(lang, 'menu.expatWallet.url') && (
                <a
                  href={translate(lang, 'menu.expatWallet.url')}
                  className="btn btn-ghost btn-sm rounded-btn"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  {translate(lang, 'menu.expatWallet')}
                </a>
              )}
            </div>
          </div>

          <div class="flex justify-end flex-1 px-2">
            <div class="dropdown dropdown-end">
              <div tabindex="0" class="btn btn-ghost rounded-btn">
                {lang}
              </div> 

              <ul tabindex="0" class="p-2 shadow menu dropdown-content bg-base-100 rounded-box w-52">
                <li className="text-black">
                  <Link to="/">
                    PL
                  </Link>
                </li>
                <li className="text-black">
                  <Link to={getUrlPrefix('en')}>
                    EN
                  </Link>
                </li> 
              </ul>
            </div>
          </div>
        </div>
      </div>
  
      <div className="sm:hidden absolute top-10 left-3">
        <label htmlFor="my-drawer-2" className="btn btn-square btn-ghost">
          <BurgerMenu />
        </label>
      </div>
    </>
  );
};

export default Menu;
