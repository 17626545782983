import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import Header from "../Header"
import Side from "../Side"
import { BurgerMenuContent, Menu } from "../Menu"

import * as styles from "./Layout.module.scss"
import "./Layout.css"

const Layout = ({ children, fullWidth = false }) => {
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {/* impact.com verification */}
        <meta name="ir-site-verification-token" value="1939975175" />
      </Helmet>

      <div className="drawer" data-theme="light">
        <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />

        <div className="drawer-content">
          <div className={styles.container}>

            <div className={styles.header}>
              <Header />
            </div>

            <Menu />

            {!fullWidth && (
              <div className={styles.columns}>
                <div className={styles.left}>
                  <main>{children}</main>
                </div>
                <div className={styles.right}>
                  <Side />
                </div>
              </div>
            )}
            {fullWidth && (
              <div className={styles.columns}>
                <main style={{ width: '100%' }}>{children}</main>
              </div>
            )}
            <footer className={styles.footer}>
              © {new Date().getFullYear()}, By Damian Sowa
            </footer>
          </div>
        </div>

        <div className="drawer-side">
          <label htmlFor="my-drawer-2" className="drawer-overlay" />
          
          <BurgerMenuContent />
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
