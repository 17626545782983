import { default as en } from './en';
import { default as pl } from './pl';

export const translations = {
  en,
  pl,
};

export const translate = (lang, key) => translations[lang][key];

export const getUrlPrefix = (lang) => {
  if(lang === 'pl') {
    return '';
  }

  return `/${lang}`;
};
