export default {
  'menu.homePage': 'Home page',
  'menu.homePage.url': '/en',
  'menu.dutchAccountsRanking': 'Dutch bank accounts ranking',
  'menu.dutchAccountsRanking.url': null,
  'menu.expatWallet': 'Expat Wallet',
  'menu.expatWallet.url': 'http://expat-wallet.com/',

  'sideBar.aboutMe': 'About me',
  'sideBar.aboutMe.description': `
    I lived in Poland and the Netherlands, actually I am fascinated in digital nomadism.
    I love to explore cities and try local food.
    I share here information about interesting places and expenses.
    Personally I am a tea lover.
  `,
  'sideBar.stayInTouch': 'Stay in touch!',
  'sideBar.stayInTouch.description': 'Social accounts and contact on:',
  'sideBar.stayInTouch.myLinks': 'My projects and links:',

  'post.readArticle': 'Read more',
  'post.publishedOn': 'Published:',
  'post.checkAlso': 'Check also:',

  'pagination.prev': 'Previous posts',
  'pagination.next': 'Next posts',
};