export default {
  'menu.homePage': 'Strona główna',
  'menu.homePage.url': '/',
  'menu.dutchAccountsRanking': 'Ranking holenderskich kont bankowych',
  'menu.dutchAccountsRanking.url': '/ranking-holenderskich-kont-bankowych',
  'menu.expatWallet': 'Expat Wallet',
  'menu.expatWallet.url': null,

  'sideBar.aboutMe': 'O mnie',
  'sideBar.aboutMe.description': `
    Mieszkałem w Polsce i Holandii, obecnie cyfrowy nomada, zafascynowany tym trendem.
    Uwielbiam poznawać nowe miejsca oraz smaki.
    Dzielę się na blogu informacjami o ciekawych atrakcjach i kosztach.
    Prywatnie jestem miłośnikiem herbaty.
  `,
  'sideBar.stayInTouch': 'Zostańmy w kontakcie!',
  'sideBar.stayInTouch.description': 'Kontakt i socjale znajdziesz na:',
  'sideBar.stayInTouch.myLinks': 'Moje projekty i linki:',

  'post.readArticle': 'Przeczytaj całość',
  'post.publishedOn': 'Opublikowano:',
  'post.checkAlso': 'Zobacz także:',

  'pagination.prev': 'Poprzednie wpisy',
  'pagination.next': 'Następne wpisy',
};