import React from 'react';
import { Link } from 'gatsby';
import { useLang } from '../../context';
import { translate, getUrlPrefix } from '../../translations';

const BurgerMenuContent = () => {
  const lang = useLang();

  return (
    <ul className="menu p-4 overflow-y-auto w-80 bg-base-100 text-base-content">
      <li>
        <Link to={translate(lang, 'menu.homePage.url')}>
          {translate(lang, 'menu.homePage')}
        </Link>
      </li>
      
      {translate(lang, 'menu.dutchAccountsRanking.url') && (
        <li>
          <Link to={translate(lang, 'menu.dutchAccountsRanking.url')}>
            {translate(lang, 'menu.dutchAccountsRanking')}
          </Link>
        </li>
      )}

      <div class="flex justify-end flex-1 px-2">
        <div class="dropdown dropdown-end">
          <div tabindex="0" class="btn btn-ghost rounded-btn">
            {lang}
          </div> 

          <ul tabindex="0" class="p-2 shadow menu dropdown-content bg-base-100 rounded-box w-52">
            <li className="text-black">
              <Link to="/">
                PL
              </Link>
            </li>
            <li className="text-black">
              <Link to={getUrlPrefix('en')}>
                EN
              </Link>
            </li> 
          </ul>
        </div>
      </div>
    </ul>
  );
};

export default BurgerMenuContent;
