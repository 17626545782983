import React from 'react';
import Widget from '../../Widget';

const Other = () => (
  <Widget>
    <a
      href="https://simpleanalytics.com/?utm_source=&utm_content=badge"
      referrerpolicy="origin"
      target="_blank"
    >
      <picture style={{ display: 'flex', justifyContent: 'center' }}>
        <source
          srcset="https://simpleanalyticsbadges.com/?mode=light"
          media="(prefers-color-scheme: dark)"
        />
        <img
          src="https://simpleanalyticsbadges.com/?mode=light"
          loading="lazy"
          referrerpolicy="no-referrer"
          crossorigin="anonymous"
        />
      </picture>
    </a>
  </Widget>
);

export default Other;
