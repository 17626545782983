import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import get from 'lodash.get';
import { useLang } from '../../../context';
import { translate } from '../../../translations';
import Widget from '../../Widget';
import * as styles from './AboutMe.module.scss';

const AboutMe = () => {
  const data = useStaticQuery(graphql`
    query {
      file(
        relativePath: {
          eq: "61295450_2130030823918901_5974757678667268096_n.jpg"
        }
      ) {
        childImageSharp {
          fixed(width: 175, height: 175) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const lang = useLang();

  const file = get(data, 'file');

  return (
    <Widget title={translate(lang, 'sideBar.aboutMe')}>
      <div>
        <div className={styles.imageContainer}>
          <Img
            className={styles.authorImage}
            fixed={file.childImageSharp.fixed}
            alt="Damian Sowa"
          />
        </div>

        <div>
          <p className={styles.authorName}>Damian Sowa</p>
          <p className={styles.description}>
            {translate(lang, 'sideBar.aboutMe.description')}
          </p>
        </div>
      </div>
    </Widget>
  )
}

export default AboutMe
